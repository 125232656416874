'use client';

import { useEffect } from 'react';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    // Optionally log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <main className="flex h-full flex-col items-center justify-center">
      <h2 className="text-center">
        We couldn&apos;t find a device or subscription associated with your
        email. Please reach out to us at contact@pachiralabs.com for assistance
        with your purchase or any questions.
      </h2>
    </main>
  );
}
